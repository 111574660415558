
/* ==========================================================================
	 Global
	 ========================================================================== */
/* ==========================================================================
	Col Aside
	========================================================================== */

.col-aside {
	@extend .col-md-3;
	@extend .col-md-pull-9;
	padding-top: 20px;
}

/* ==========================================================================
	Col Main
	========================================================================== */

.col-main {
	@extend .col-md-9;
	@extend .col-md-push-3;
	padding-bottom: 60px;
	@extend .bloc-contenu;
}

*::selection {
	background-color: #B3D4FB;
}

// .moule {
// 		background: url(../images/charte/bg.png) top center repeat-y;
// }


h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	// @extend .text-center;
	// text-transform: uppercase;
	font-family: 'Montserrat', sans-serif;
	// font-weight: 900;
	// font-style: italic;
  // color: $brand-tierce;
  color: $brand-primary;
  // color: white;
  // color: $link-color;
	small{
		// text-transform: capitalize;
		display: block;
		padding-top: 5px;
		color: lighten($brand-tierce, 15%);
	  // font-weight: 500;
	}
}

h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
	// @extend .text-center;
  margin: 0 auto 50px auto;
	position: relative;
	padding-bottom: 50px;
	&:after{
		content : '';
		position: absolute;
		bottom: 0;
		left:0;
		width: 40px;
		height: 2px;
		background-color: $brand-second;
	}
}
// h1 {
  // box-shadow: $box-shadow;
// }
.page-header-top, h1.page-header-top {
	// >h1 {
	// 	color: $brand-primary !important;
	// }


	@extend .section-blanche;
	// background: linear-gradient(rgba($body-bg, .15), rgba($body-bg, 1));;
	// @extend .bloc-contenu;
	@extend .clearfix;
	// @extend .container-max;
	@extend .text-center;
	// @extend .rowflex;
  // border-bottom: solid 2px $brand-primary;
  border-top: solid 2px $brand-primary;
  border-bottom: solid 2px $brand-second;
  // border-radius: $border-radius-base;
  // box-shadow: $box-shadow;
  // background-color: rgba($brand-second, 0.75);
  // background-color: $body-bg !important;
	// background-color: rgba(#000, .4);
	// margin: 0px auto 40px auto !important;
  padding: 40px 5px !important;
  // background-color: lighten($body-bg, 20%);
  // background: url(../images/charte/mandala.svg) top center no-repeat lighten($body-bg, 20%);
  position: relative;
	h1,p.h1 {
	  @extend .animated;
	  @extend .fadeInUp;
	  animation-delay: .5s;
		@extend .container;
	  // color: lighten($brand-primary, 15%);
	  // color: $brand-second;
		margin: 20px auto;
		// color: #fff !important;
		// text-shadow: 1px 3px 6px rgba(#000, 0.05);
		small {
		  @extend .animated;
	  	@extend .fadeInUp;
	  	animation-delay: .8s;

		}
	}
	// padding-bottom:30px;
	// &:after{
	// 	content : '';
		
	// 	position: absolute;
	// 	bottom: 0;
	// 	left:0;
	// 	width: 40px;
	// 	height: 3px;
	// 	background-color: $brand-second;
	// }
}

.btn, a, button {
	outline: 0 !important;
	transition: all linear 0.3s;
	// border-radius: $border-radius-base;
}

.thumbnail, .panel, .well, .table {
	// border: solid 1px rgba($brand-primary, .3) !important;
	box-shadow: $box-shadow;
	// border-bottom: solid 5px $brand-second;
	// background: url(../images/charte/mandala.svg) top center no-repeat $body-bg;
	// color: $text-color;
	// margin: 20px auto;
	img {
		// border-radius: 5px !important;
	}
}
.well {
	@extend .texte-blanc;
	padding:30px 20px 20px 20px !important;
	a {
		@extend strong;
		// color: lighten($well-bg, 19%) !important;
	}
}
.container-max {
	@extend .container-fluid;
	// @extend .no-pad;
	max-width: 1400px;
}

.no-pad {
	padding: 0 !important;
}

.panel-heading {
	background-color: rgba($brand-second, 0.5) !important;
}
.panel-body {
	// color: #141414 !important;
}

/* ==========================================================================
	 Headers
	 ========================================================================== */

@import "header-basic";
// @import "header-basic-2";
// @import "header-slider-1";
// @import "header-slider-2";
// @import "header-slider-3";
// @import "header-slider-4";



/* ==========================================================================
	Navbar
	========================================================================== */

.navbar-centered {
	// @extend .container-max;
	@media (min-width: $grid-float-breakpoint) {
		.navbar-nav {
			display: inline-block;
			float: none;
			vertical-align: top;	
		}

		.navbar-collapse {
			text-align: center;
		}
	}
}
.sticky-top {
	position: sticky;
	top: 0;
}
// .btn-top {
// 	@extend .sticky-top;
// 	margin-top: -.6rem;
// 	left: 10%;
// 	z-index: 500;
// 	border-top: none !important;
// 	border-radius: 0 0 20px 20px;
// 	color: #fff;
// 	max-width:25%; 
//   background-color: #e14632;
//   text-align: center;
//   border: 3px solid #fff!important;
//   box-shadow: 0 5px 15px rgba(17,4,3,.1)!important;
	// font-family: 'Black Ops One', cursive;
	// font-weight: 500;
// }


.navbar {
	// font-family: 'Bangers', cursive;
	@extend .sticky-top;
	@extend .navbar-centered; // pour centrer le contenu de la navbar.
	// @extend .navbar-default;
	// background: linear-gradient(rgba($brand-second, 0.5), rgba($brand-second, 0.75));;
	// border-top: solid 5px $brand-primary;
	// margin: 0px auto;
	// position: relative;
		// z-index: 300 !important;
	// top:30px;
  box-shadow: $box-shadow;
  // width: 100%;
  font-size: 18px;
	// font-weight: 600;
}

.navbar-nav {
	// font-size: 15px;
	// li {
	// @media (min-width: $grid-float-breakpoint) {
	// 	border-radius: $border-radius-large $border-radius-large 0 0;
	// 	margin:  010px;
	// 	}
	// 	background-color: white;
	// 	border-bottom: solid $brand-primary 1px;
	// 	a {
	// 		padding: $navbar-padding-vertical;
	// 		font-size: $font-size-h4;
	// 	}
		// &:nth-child(3) {
		// 	&:hover {
		// 		a {
		// 		color: darken($brand-primary, 10%);
		// 		background-color: white;
		// 		}
		// 	}
		// 	&:active,
		// 	&:focus,
		// 	&.active {
		// 		a {
		// 		color: white;
		// 		background-color: darken($brand-primary, 10%);
		// 		}
		// 	}
		// }

		// &:nth-child(4) {
		// 	&:hover {
		// 		a {
		// 		color: $brand-second;
		// 		background-color: white;
		// 		}
		// 	}
		// 	&:active,
		// 	&:focus,
		// 	&.active {
		// 		a {
		// 		color: white;
		// 		background-color: $brand-second;
		// 		}
		// 	}
		// }

		// &:nth-child(5) {
		// 	&:hover {
		// 		a {
		// 		color: $brand-tierce;
		// 		background-color: $brand-primary;
		// 		}
		// 	}
		// 	&:active,
		// 	&:focus,
		// 	&.active {
		// 		a {
		// 		color: $brand-primary;
		// 		background-color: $brand-tierce;
		// 		}
		// 	}
		// }

		// &:nth-child(6) {
		// 	&:hover {
		// 		a {
		// 		color: $brand-quatro;
		// 		background-color: white;
		// 		}
		// 	}
		// 	&:active,
		// 	&:focus,
		// 	&.active {
		// 		a {
		// 		color: white;
		// 		background-color: $brand-quatro;
		// 		}
		// 	}
		// }

		// margin-left: 5px;
		// }
		a {
			// display: block !important;
			// position: relative;
			// padding-left: 55px !important;
			// &::before {
				// content:"";
				// position: absolute;
				// left: 0;
				// top: 50%;
				// width: 50px;
				// height: 50px;
				// margin-top: -25px;
				// margin-right: 10px;
				// display: inline-block;
				// background: url(../images/charte/menu.png) top center no-repeat;
				// line-height: 24px;
		// } 
	}
	// @extend .navbar-right; // pour aligner � droite.
}

.index-nav {
	@extend .pull-right;
	display: inline-block;
	line-height: $navbar-height;
	padding-right: 10px;
	// color: $body-bg;
	color: $text-color;
	// color: $brand-primary;
	text-transform: uppercase;
	// font-weight: 700;
	
	@media (min-width: $grid-float-breakpoint) {
		display: none;
	}
}
.icon-bar, .navbar-toggle {
	// background-color: $body-bg;
	color: $body-bg;
	background-color: $text-color;
}

/* ==========================================================================
	Navbar e-commerce
	========================================================================== */

.navbar-top {
	position: absolute;
	z-index: 1000;
	top: 0;
	left: 0;
	right: 0;
	z-index: 3000;
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-end;
	background-color: white;
	padding:  10px;
	font-size: 14px;
	
	>a,
	>button {
		border:none;
		display: inline-block;
		background-color: transparent;
		padding:  10px;
		height: 35px;
		line-height: 35px;
			border-left: solid 1px $gray-lighter;
			color: $gray-darker;
	
			&:hover {
				color: $brand-primary;
		}
	
			>i {
				margin-right: 5px;
			}
		}
	}
	
.dropdown-panel {
	position: absolute;
	z-index: 3000;
	top: 35px;
	right: 0;
	width: 50px;
	max-width: 100%;
	padding:30px;
	background-color: white;
	box-shadow:  010px30px rgba(black, .1);
}
	
.dropdown-search {
	@extend .dropdown-panel;
}
	
.dropdown-cart {
	@extend .dropdown-panel;
}
	
.dropdown-account {
	@extend .dropdown-panel;
}
	
.btn-close {
	@extend .btn;
	@extend .btn-primary;
	@extend .btn-sm;
}


/* ==========================================================================
	 Main
	 ========================================================================== */

.main {
	@extend .clearfix;
	// @extend .container-max;
	// @extend .no-pad;
	min-height: 50px;
	// background: url(../images/charte/BG.png) center fixed;
	// background-color: rgba($body-bg, .5);
	// background: url(../images/charte/bg.png) top center;
  // background-color: rgba(lighten($brand-second, 5%), .7);
  // background: url(../images/BG-container.png);
  // background-repeat: no-repeat;
  // background-position: bottom center;
  // background-size: 100%;
  // background: linear-gradient(rgba(white, 0.4), rgba(white, 0.2), rgba(white, 0.2));
  // border-top: solid $brand-primary 2px;
  // padding: 0 20px 70px 20px;
  // padding-bottom: 70px;
}

.container-main {
	// background-color:white;
	// @extend .bloc-contenu;
	// @extend .container;
	@extend .clearfix;
	overflow: hidden;
	// padding: 15px;
 //    @media(min-width: $screen-md-min) {
	// 	padding: 0 15%;
	// }
  // border: solid 2px $brand-second;
  // padding: $line-height-computed;
  // border-radius: $border-radius-base;
}

.bloc-contenu {
	// background-color: rgba($brand-second, .8);
	// @extend .container-max;
  // border: solid .1rem rgba($brand-second, .85);
  // border: solid .1rem $brand-second;
  // border-bottom: solid 2px $brand-primary;
  // border-bottom: solid 3px rgba($brand-primary, .25);
  // border-top: solid 3px $brand-second;
  // border-right: solid 3px $brand-second;
  // border-left: solid 3px $brand-second;
  // background-color: $body-bg;
	background-color: rgba($body-bg, .6);
	// background: url(../images/charte/mandala.png) top right no-repeat rgba($body-bg, .9);
  box-shadow: $box-shadow;
	// border-radius: $border-radius-large;
	overflow: hidden;
	color: $text-color;
  margin: 20px auto;
  padding: 3rem;
  // padding: 20px 30px 50px 30px;
	border-radius: 0 3rem;
	// border-radius: $border-radius-large;
//   >h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
//   color: $brand-primary !important;
// }
}

/* ==========================================================================
	 Footer
	 ========================================================================== */


.panel-ref-footer {
	@extend .bloc-contenu;
	@extend .container-max;
	@extend .text-center;
}
	 
.footer {
	// margin-top: 70px;
  box-shadow: $box-shadow;
 	border-top: solid 3px $body-bg;
 	padding-bottom: 50px;

	// background: url(../images/charte/mandala.svg) top center no-repeat $body-bg;
  // background-color: darken($brand-second, 20%);
  // background-color: white;

//// FOOTER NEGATIF ////


  // background-color: $body-bg;
  // color: $text-color;
  background-color: $brand-primary;
  // background: linear-gradient(rgba(#9B9B9B, 1), rgba(#787878, 1));;
  color: white;
a {
	color: lighten($brand-second, 5%);
	// color: $text-color;
}

	address {
		margin: 0;
	}
}

.container-footer {
	@extend .container-max;

	@media (max-width: $screen-xs-max) {
		text-align: center;
	}
}

.footer-main {
	@extend .clearfix;
	padding: 60px 0;
}

ul.list-footer {
	@extend .list-unstyled;
	margin: 0;
	line-height: 23px;

	@media (max-width: $screen-xs-max) {
		padding-top: 20px;
		margin-top: 20px;
		border-top: solid 1px rgba(black, .2);
	}
}

ul.list-footer-right {
	@extend .list-unstyled;
	margin: 0;
	line-height: 23px;
}

ul.list-logo-footer {
	@extend .list-inline;
	@extend .text-right;
	margin: 0;

	>li {
		padding: 0;
	}

	@media (max-width: $screen-sm-max) {
		text-align: center;
		padding-top: 20px;
		margin-top: 20px;
		border-top: solid 1px rgba(black, .2);
	}

	img {
		height: 37px;
	}
}

ul.list-logo-footer-big {
	@extend .list-logo-footer;
	margin: 0;
	img {
		height: 8rem;
	}
}
.panel-mini-caddie-footer {
	@extend .panel;
}

.mini-caddie-quant {
	display: inline-block;
	padding-left: 10px;
}

/* ==========================================================================
	 Custom
	 ========================================================================== */
.table-tarifs {
	@extend .table;
	@extend .table-striped;

	td:nth-child(2) {
		text-align: right;
		font-weight: 700;
		color: $brand-danger;
	}
}

.card {
	// background-color: rgba(white, .7);
	// @extend .text-center;
	background-color: $body-bg;
	margin-bottom: 20px;
	padding: 20px 20px30px 20px;
	box-shadow: $box-shadow;
	// color: $body-bg;
	color: $text-color;
	// font-size: $font-size-large;
	border-radius: $border-radius-base;
	// border: solid 2px rgba($brand-primary, .2);
	>.img-bulle {
		border: solid .1rem rgba($brand-second, .5);
		margin-top: -5rem;
	}
	>h2, h3, h4, .h2, .h3, .h4 {
		padding: 20px;
		margin: 0;
		// background-color: $brand-primary;
		// color: white;
		// color: $brand-primary !important;
	}
	>p {
		padding: 10px;
		margin: 0;
	}
}

section {
	padding: 50px 0!important;
	// padding:  50px 10px 60px 10px !important;
	// @extend .text-center;
	// margin-bottom: 40px;
	border-bottom: solid 3px $body-bg;
	@extend .no-pad;
	.panel {
		color: $text-color;
	}
	.thumbnail {
		// margin:30px;
		// padding: 0;
		border: none;
		box-shadow: $box-shadow; 
	}
	/////
	.conteneur {
		@extend .container;
	}
	/////
	.conteneur,.container-max, .container {
		// >h2,.h2,h3,.h3 {
			// margin-top: 0px !important;
			// padding-top: 0px !important;
		// }
		padding: 20px !important;
		// margin: 20px !important;
	}
	min-height: 30vh;
	margin: 0;
	padding: 0;
	position: relative;
	// font-size: $font-size-large;
	// text-align: center;
	// h3, h4 {
	// 	color: white;
	// }
	h2,h3,h4 {
		// @extend .text-center;
		margin-bottom: 20px !important;
		// display: block;
		padding: 0 0 30px 0;
		// background-color: #fff;
	}
}
.section-tarifs {
	background-color: rgba(white, .1);

	.table-responsive {
		box-shadow: $box-shadow;
		margin-bottom: $line-height-computed;

		>.table-tarifs {
			margin: 0;
		}
	}
}

.section-actu {
	@extend .section-second;
}
.section-transparence {
	background-color: rgba(#fff, .05);
	// background-color: rgba(lighten($brand-second, 25%), .55);
	// border-top: solid 1px rgba($brand-second, .8);
	// border-bottom: solid 1px rgba($brand-second, .8);
	>h1,.h2,h2,.h2,h3,.h3 {
		color: $brand-primary;
	}
}
.section-blanche {
	// border-top: solid 1px rgba($brand-primary, .8);
  // border-bottom: solid 3px $brand-primary;
	// background-color: #fff;
	background-color: rgba($body-bg, .95);
	// background: linear-gradient(rgba($body-bg, .2), rgba($body-bg, 1));;
	// color: $body-bg;
}
.section-primary {
	// background-color: $brand-primary;
	background-color: rgba($brand-primary, .75);

	// border-bottom: solid 3px $brand-primary;
	  // background: url(../images/accueilBG.png) top center no-repeat rgba($brand-primary, .95);
		color: white;
	// color: $brand-second;
	// .well {
		// background-color: $body-bg;
		// background-color: $brand-second;
	// }
	// a {
	// 		color: $brand-second;
	// 	}
	// .slick-dots li.slick-active button::before {
	// 	color: $brand-second;
	// }
		.slick-dots li button::before {
			color: $body-bg;
		}
	h2,.h2,h3,.h3,h4,.h4,h5,.h5 {
		color: darken($brand-primary, 90%);
		>small {
			color: darken($brand-primary, 35%);
		}
	}

}
.section-second {
	// border-bottom: solid 3px $brand-second;
	background-color: rgba(lighten($brand-second, 10%), .85);
	color: $text-color;
	a {
		color: $brand-tierce !important;
	}
	h2,.h2,h3,.h3,h4,.h4,h5,.h5 {
		color: lighten($brand-primary, 10%);
		>small {
			color: $brand-second;
		}
	}
}
.multi-pills {
	// @extend .text-center;
	>li {
		background-color: $brand-primary;
		// color: $brand-second !important;
	}
}
.section-tierce {
	background-color: $brand-tierce;
// 	// color: white;
}
// .section-quatro {
// 	background-color: $brand-quatro;
// 	color: white;
// }
.fond-image-section {
	min-height: 60vh;
	h1, img {
		position: relative;
		z-index: 200 !important;
		small {
			color: lighten($brand-primary, 10%) !important;
		}
	}
	&::after{
		content:'';
		position: absolute;
		top:0;
		left:0;
		right:0;
		bottom:0;
		// background-color: rgba(white, .75);
		// background-color: rgba($gray-darker, .45);
		background-color: rgba($brand-primary, .55);
		// background-color: rgba($brand-second, .5);
  // background: url(../images/charte/BG-section.png) bottom center repeat-x;
		z-index: 0;
		// min-height: 33vh;

	}
}
.section-image {
	@extend .fond-image-section;
	padding: 10vh 15%;
	// margin: 40px 0;
	.container-max, .container, .conteneur, hr {
		position: relative;
		z-index: 10;
	}

	// h2,.h2,h3,.h3,h4,.h4 {
	// 	color: lighten($brand-primary, 60%) !important;
	// }
	// a {
	// 	color: white;
	// }
	// background: url(../images/charte/section.jpg) bottom center;
	// background-size: cover;
  border-top: solid 2px $brand-primary;
  // border-bottom: solid 2px $brand-primary;
	// color: white;
	// color: darken($brand-primary, 25%);
}
.section-image-01 {
		@extend .fond-image-section;
	.container-max, .container, .conteneur {
		position: relative;
		z-index: 10;
	}
	h2,.h2,h3,.h3,h4,.h4 {
		// color: lighten($brand-primary, 60%);
	}
	// color: white;
	background: url(../images/charte/image-01.jpg) bottom center fixed;
	background-size: cover;
	border-bottom: solid 3px $brand-primary;
	overflow: hidden;
// 	border-top: solid 1px $brand-primary;
// 	// color: darken($brand-primary, 25%);
}
.section-image-02 {
		@extend .fond-image-section;
	.container-max, .container, .conteneur {
		position: relative;
		z-index: 10;
	}
	// h2,.h2,h3,.h3,h4,.h4 {
	// 	color: lighten($brand-primary, 60%) !important;
	// }
	// color: white;
	background: url(../images/charte/image-02.jpg) bottom center fixed;
	background-size: cover;
	border-bottom: solid 3px $brand-primary;
	overflow: hidden;
// 	border-top: solid 1px $brand-primary;
// 	// color: darken($brand-primary, 25%);
}

// .section-image-03 {
// 		@extend .fond-image-section;
// 	.container-max, .container, .conteneur {
// 		position: relative;
// 		z-index: 10;
// 	}
// 	h2,.h2,h3,.h3,h4,.h4 {
// 		color: lighten($brand-primary, 60%) !important;
// 	}
// 	// color: white;
// 	background: url(../images/charte/image-03.jpg) bottom center fixed;
// 	background-size: cover;
// 	border-bottom: solid 3px $brand-primary;
// 	overflow: hidden;
// // 	border-top: solid 1px $brand-primary;
// // 	// color: darken($brand-primary, 25%);
// }
// .section-image-04 {
// 		@extend .fond-image-section;
// 	.container-max, .container, .conteneur {
// 		position: relative;
// 		z-index: 10;
// 	}
// 	h2,.h2,h3,.h3,h4,.h4 {
// 		color: lighten($brand-primary, 60%) !important;
// 	}
// 	// color: white;
// 	background: url(../images/charte/image-04.jpg) bottom center fixed;
// 	background-size: cover;
// 	border-bottom: solid 3px $brand-primary;
// 	overflow: hidden;
// // 	border-top: solid 1px $brand-primary;
// // 	// color: darken($brand-primary, 25%);
// }

// .section-image-05 {
// 		@extend .fond-image-section;
// 	.container-max, .container, .conteneur {
// 		position: relative;
// 		z-index: 10;
// 	}
// 	h2,.h2,h3,.h3,h4,.h4 {
// 		color: lighten($brand-primary, 60%) !important;
// 	}
// 	// color: white;
// 	background: url(../images/charte/image-05.jpg) bottom center fixed;
// 	background-size: cover;
// 	border-bottom: solid 3px $brand-primary;
// 	overflow: hidden;
// // 	border-top: solid 1px $brand-primary;
// // 	// color: darken($brand-primary, 25%);
// }
// .section-image-06 {
// 		@extend .fond-image-section;
// 	.container-max, .container, .conteneur {
// 		position: relative;
// 		z-index: 10;
// 	}
// 	h2,.h2,h3,.h3,h4,.h4 {
// 		color: lighten($brand-primary, 60%) !important;
// 	}
// 	// color: white;
// 	background: url(../images/charte/image-06.jpg) bottom center fixed;
// 	background-size: cover;
// 	border-bottom: solid 3px $brand-primary;
// 	overflow: hidden;
// // 	border-top: solid 1px $brand-primary;
// // 	// color: darken($brand-primary, 25%);
// }
// .section-image-07 {
// 		@extend .fond-image-section;
// 	.container-max, .container, .conteneur {
// 		position: relative;
// 		z-index: 10;
// 	}
// 	h2,.h2,h3,.h3,h4,.h4 {
// 		color: lighten($brand-primary, 60%) !important;
// 	}
// 	// color: white;
// 	background: url(../images/charte/image-07.jpg) bottom center fixed;
// 	background-size: cover;
// 	border-bottom: solid 3px $brand-primary;
// 	overflow: hidden;
// // 	border-top: solid 1px $brand-primary;
// // 	// color: darken($brand-primary, 25%);
// }
// .section-image-08 {
// 		@extend .fond-image-section;
// 	.container-max, .container, .conteneur {
// 		position: relative;
// 		z-index: 10;
// 	}
// 	h2,.h2,h3,.h3,h4,.h4 {
// 		color: lighten($brand-primary, 60%) !important;
// 	}
// 	// color: white;
// 	background: url(../images/charte/image-08.jpg) bottom center fixed;
// 	background-size: cover;
// 	border-bottom: solid 3px $brand-primary;
// 	overflow: hidden;
// // 	border-top: solid 1px $brand-primary;
// // 	// color: darken($brand-primary, 25%);
// }



.section-map {
	h2,.h2,h3,.h3,h4,.h4 {
		color: lighten($brand-primary, 60%) !important;
	}
	background-color: $brand-tierce;
	margin: 0 !important;
	padding: 50px 0 0 0 !important;

	.bloc-contenu{
		margin: 40px;
	}
	.embed-responsive {
		box-shadow: $box-shadow;
		background: url(../images/charte/loader.svg) no-repeat center black;
	}
}
.section-video {
	background-color: rgba($brand-primary, .6);
	color: white;

	.embed-responsive {
		box-shadow: $box-shadow;
		background: url(../images/charte/loader.svg) no-repeat center black;
	}
}

.section-slider-large {
	background-color: rgba(black, .5);
	@extend .text-center;
	color: white;

	.content-slider {
		@extend .center-block;
		max-width: 100px;
	}

	.carousel {
		@extend .carousel-fade;
		background-color: white;
		box-shadow: $box-shadow;
	}
}

.section-content-slider {
	background-color: rgba($brand-primary, .5);
	color: white;

	.carousel {
		@extend .carousel-fade;
		background-color: white;
		box-shadow: $box-shadow;
	}
}

.section-center {
	// background-color: rgba(#464646, .8);
	border-top: solid 2px $brand-primary;
	background: url(../images/footer.jpg) bottom center fixed;
	background-size: cover;
	>.container-max>h1 {
		@extend .texte-blanc;
		margin: 10px auto;
	}
}
.img-bulle {
	@extend .img-circle;
	@extend .img-responsive;
	@extend .center-block;
	// height: 30px;
	max-height: 200px;
	// border-radius: 100px;
  // border: solid 2px $brand-primary;
  border: solid 2px lighten($brand-second, 25%);
  box-shadow: $box-shadow;
  margin: 20px auto;
  background-color: #fff;
  padding: 0;
  transition: transform ease-out .5s;
  &:hover {
    transition: transform ease-in .5s;
	  border: solid 2px lighten($brand-second, 5%);
  }
}
.panel-large {
	margin:30px auto;
	max-width: 100px;
	padding:30px;
	background-color: white;
	font-size: $font-size-large;
	color: $text-color;
	box-shadow: $box-shadow;

	>p:last-child {
		margin-bottom: 0;
	}
}

.section-center {
	@extend .text-center;
	margin-bottom: -$line-height-computed;
}

.content-cards {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-around;

	.card {
		width: 40px;
		margin: 20px;
	}
}

.big-card-center {
	@extend .panel-large;
	padding: 0;
}

.content-highlight {
	margin:30px auto;
	max-width: 100px;
	font-size: $font-size-large;
}

.panel-highlight {
	margin: 50px auto;
	max-width: 100px;
	font-size: $font-size-large;
	padding:30px;
	background-color: white;
	box-shadow: $box-shadow;

	>h3 {
		margin: 10px 0 20px;
	}
}


.texte-blanc {
  color: #fff !important;
  text-shadow: 1px 2px 4px rgba($brand-primary, .25);
}

// .panel-ardoise {  
//   background: url(../images/charte/bg-ardoise.jpg) center top;
//   border: solid 20px transparent;
//   border-image: url(../images/charte/bois.jpg) 30 round;
//   box-shadow: $box-shadow;
//   padding: $line-height-computed;
//   margin:30px auto;
//   .caption {
//   	  color: #fff !important;
//   }
// }

// .panel-livre {
//   @media (min-width: $screen-md-max) {
//   background: url(../images/charte/livre.png) center top no-repeat;
//   background-size: 100%;
//   height: 840px;
//   }
//   .padding-dedans {
//     @media (min-width: $screen-md-max) {
//     padding: $line-height-computed*4 $line-height-computed*2 $line-height-computed*2 $line-height-computed*4;
//     }
//   }
// }
.container-thumb{
	display: flex;
	height: 156px;
	overflow: hidden;
	align-items: center;
	justify-content: center;
	align-items: center;
	@media (max-width:$screen-sm) {
		height: auto;
	}
	
	img{
		height: auto;
		width: 100%;
	}
}
// .panel-second{
// 	@extend .panel-primary;
// 	background-color: $brand-second !important;
// }

.multi-pills {
	@extend .list-inline;
	// @extend .text-center;
	>li {
		// background-color: $brand-second;
		// background-color: $brand-primary !important;
		// color: $brand-primary !important;
		color: #fff !important;
		border: solid 3px white;
		border-radius: 20px;
		padding: 5px 1.6rem;
		// height: 60px;
		// line-height: 5.8rem;
		// font-size: 15px;
		margin: 05px;
		background-color: $brand-primary !important;
		// color: $brand-second !important;

		>a {
			padding: 50px !important;
			color: #fff !important;
		}
	}
}
.maxi-pills {
	@extend .multi-pills;
	>li {
		// font-family: 'Michroma', sans-serif;
		// height: 12rem;
		background-color: $brand-primary !important;
		font-size: 25px;
		padding: 10px 20px !important;
		margin: 10px;
		// color: $brand-second !important;
		box-shadow: $box-shadow;

		>a {
			color: #fff !important;
		}
	}
}
.fond-blanc {
	background-color: #fff !important;
	// color: $text-color;
	padding: 20px;
}

// .animation-img {
//   @extend .animated;
//   @extend .fadeInDown;
// }
// .texte-classique {
	// color: $text-color !important;
// }

// .quote-container {
//   margin-top: 50px;
//   position: relative;
//   color: #333 !important;
// }

// .note {
//   color: #333 !important; 
//   position: relative;
//   width: 100%;
//   margin: 0 auto;
//   padding: 20px;
//   font-size: 15px;
//   box-shadow: 0 10px 10px 2px rgba(0,0,0,0.3);
// }

// .note .author {
//   display: block;
//   margin: 40px 0 0 0;
//   text-align: right;
// }

// .yellow {
//   background: #eae672;
//   color: #333 !important;
//   -webkit-transform: rotate(2deg);
//   -moz-transform: rotate(2deg);
//   -o-transform: rotate(2deg);
//   -ms-transform: rotate(2deg);
//   transform: rotate(2deg);
// }

// .yellow p{

//   color: #333 !important;

// }


// .pin {
//   background-color: #aaa;
//   display: block;
//   height: 32px;
//   width: 2px;
//   position: absolute;
//   left: 50%;
//   top: -16px;
//   z-index: 1;
//   color: #333 !important;
// }


// .pin:after {
//   background-color: #A31;
//   background-image: radial-gradient(25% 25%, circle, hsla(0,0%,100%,.3), hsla(0,0%,0%,.3));
//   border-radius: 50%;
//   box-shadow: inset 0 0 0 1px hsla(0,0%,0%,.1),
//               inset 3px 3px 3px hsla(0,0%,100%,.2),
//               inset -3px -3px 3px hsla(0,0%,0%,.2),
//               23px 20px 3px hsla(0,0%,0%,.15);
//   content: '';
//   height: 12px;
//   left: -5px;
//   position: absolute;
//   top: -10px;
//   width: 12px;
// }


// .pin:before {
//   background-color: hsla(0,0%,0%,0.1);
//   box-shadow: 0 0 .25em hsla(0,0%,0%,.1);
//   content: '';

//   height: 24px;
//   width: 2px;
//   left: 0;
//   position: absolute;
//   top: 8px;

//   transform: rotate(57.5deg);
//   -moz-transform: rotate(57.5deg);
//   -webkit-transform: rotate(57.5deg);
//   -o-transform: rotate(57.5deg);
//   -ms-transform: rotate(57.5deg);

//   transform-origin: 50% 100%;
//   -moz-transform-origin: 50% 100%;
//   -webkit-transform-origin: 50% 100%;
//   -ms-transform-origin: 50% 100%;
//   -o-transform-origin: 50% 100%;
// }

.rowflex {
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	justify-content: center;
}

.megaflex {
	display: flex;
	flex-flow: row wrap;

	.moitie {
		width: 100%;
			@media (min-width: $screen-md-min) {
				width: 50%;
			}
	}
}

.form-control {
    width: 100%;
    height: 48px;
    padding: 10px 15px;
    // background-color: rgba($brand-second,.75) !important;
    // background-image: none;
    // border: 1px solid transparent;
    border-radius: 0;
}
// .img-logo {
	// max-width: 20px;
	// margin: 10px;
// }

// .text-gray {
	// color: #565658 !important;
// }
.breadcrumb {
	// margin-top: 10px;
	padding: 20px;
	@extend .bloc-contenu;
	@extend .container-max;
}
.panel, .thumbnail, .footer-accueil {
	h1,.h1, h2, .h2, h3, .h3, h4, .h4 {
		color: $brand-primary !important;
	}
}
.radius-bord {
	border-radius: 10px;
	padding: 50px;
	border: solid 3px $brand-second;
}

.margin-neg {
	margin-top: -2rem;
	@media (min-width: $screen-md-min) {
	margin-top: -8rem !important;
	}
}
.margin-neg-ultra {
	margin-top: -8rem;
	@media (min-width: $screen-md-min) {
	margin-top: -16rem !important;
	}
}
.margin-neg-inverse {
	margin-top: 20px;
	@media (min-width: $screen-md-min) {
	margin-top: 8rem !important;
	}
}

.contre-margin {
	margin-bottom: 11rem !important;
}

.gris{
	color: #787878;
}


.btn {
	// color: $brand-primary !important;
	// color: white !important;
	// background-color: $brand-second !important;
	// border: solid 3px $brand-primary !important;
	//border: solid 3px white !important;
	//box-shadow: $box-shadow;
}

// .btn-default {
	// color: $brand-primary !important;
	// color: white !important;
	// background-color: $brand-primary !important;
	// border: solid 3px $brand-primary !important;
	// border: solid 3px white !important;
	// box-shadow: $box-shadow;
// }

hr {
	@extend .animated;
	@extend .fadeInUp;
	// height: 6rem;
	// background-image: url(../images/charte/hr.png);
	// background-position: center;
	// background-repeat: no-repeat;
	// background-size: contain;

// -webkit-filter: drop-shadow(5px 5px 5px $brand-second );
// filter: drop-shadow(0px 5px 5px rgba(black, .05));

	@media (min-width: $screen-md-min) {
	margin: 40px auto !important;
	}
}
.img-parralax {
	// @extend .thumbnail;
	// border: solid .1rem $brand-primary;
	// border-radius: $border-radius-base;
	// box-shadow: $box-shadow;
	height: 40vh;
}

.outa {
	@extend .center-block;
	@extend .img-responsive;
	@extend .no-pad;
	margin-top: -40px;

}
.goto {
	@extend .center-block;
	@extend .img-responsive;
	@extend .no-pad;
	margin-bottom: -40px;
}

.list-group-item-special {
	@extend .list-unstyled;
	padding: 10px 20px;
	margin: 10px 0 0 0 !important;
	background-color: $body-bg;
}
.caption {
	// @extend .text-center;
}


.blockquote-center {
	@extend .text-center;
	max-width: 100px;
	padding: 50px30px;
	margin: 50px auto;
	border:none;
	background-color: white;
	// font-family: $font-family-serif;
	font-style: italic;
	position: relative;

	&::before {
		content: '\f10d';
		font-family: 'fontAwesome';
		color: $brand-primary;
		font-style: normal;
		position: absolute;
		top: -40px;
		left: 50%;
		width: 8rem;
		height: 8rem;
		margin-left: -40px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: white;
	}

	>footer {
		// font-family: $font-family-sans-serif;
		font-style: normal;
		position: relative;
		display: inline-block;

		&::before {
			content: '';
			position: absolute;
			top: 50%;
			right: 100%;
			width: 10px;
			height: 1px;
			margin-right: 10px;
			background-color: $gray-light;
		}

		&::after {
			content: '';
			position: absolute;
			top: 50%;
			left: 100%;
			width: 10px;
			height: 1px;
			margin-left: 10px;
			background-color: $gray-light;
		}
	}
}
.schemaflex {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	>.thumbnail {
		&::after {
			content: '\f0a9';
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 40px;
			font-family: fontAwesome;
			position: absolute;
			top: 50%;
			right: -2rem;
			width: 40px;
			height: 40px;
			background-color: #fff;
			margin-top: -2rem;
			border-radius: 50%;
			box-shadow: $box-shadow;
		}
		&:last-child::after {
			display: none;
		}
		width: 32rem;
		margin: 15px;
		position: relative;
		@extend .text-center;
		>.caption {
			font-size: 15px;
		}
	}
}

.btn-lg, .superside {
	font-family: 'Montserrat', sans-serif;
	// font-weight: 900;
}
.cinquieme {
	margin: 20px auto;
	padding: 20px 0; 
	@extend .list-inline;
	// @extend .text-center;
	@extend .center-block;

	>li {
	@extend .col-sm-6;
		@media (min-width: $screen-md-min) {
			width: 20%;
		}
	}
}
.demi-fond {
	height: 70vh;
	margin: 0 0 -70vh 0;
	border-bottom: solid .1rem $brand-primary;
}
// .caption {
// 	a {
// 		color: white;
// 	}
// }

.inner-section {
	padding: 20px 10%;
}

.cadre-hover {
	position: relative;
	overflow: hidden;

	>.caption-hover {
		position: absolute;
		top: 100%;
		height: 100%;
		width: 100%;
		transition: ease top .5s;
		background-color: rgba($brand-primary, .8);
		color: white;
		padding:30px;
		font-size: $font-size-h3;
		@extend .text-center;
		>p {
			margin-top: 30%;
		}
	}
		&:hover>.caption-hover {
			top:0;
		}
}
.list-chrono {
	margin: 20px;
	@extend .list-unstyled;
	// font-size: $font-size-large;
	>li {
		>span {
			color: $brand-primary;
			display: block;
		}
		border-left: solid 2px $brand-second;
		padding: 0 20px 20px 40px ;
		position: relative;
		&::after {
			content: '';
			position: absolute;
			top: 10px;
			left: -11px;
			width: 20px;
			height: 20px;
			background-color: $brand-primary;
			border-radius: 50%;
		}
	}
}
.bg-bottom {
	height: 22rem;
	padding: 0;
	background: url(../images/bottomBG.jpg) bottom center no-repeat #fff;
}


/* -------------------------------------------------
	AVANT - APRES
---------------------------------------------------- */



.ba-slider {
  position: relative;
  overflow: hidden;
}

.ba-slider img {
  width: 100%;
  display: block;
}

.resize {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 50%;
  overflow: hidden;
}


.handle {
  /* Thin line seperator */
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;
  width: 4px;
  margin-left: -2px;

  background: rgba(0, 0, 0, .5);
  cursor: ew-resize;
}

.handle:after {
  /* Big orange knob  */
  position: absolute;
  top: 50%;
  width: 64px;
  height: 64px;
  margin: -32px 0 0 -32px;

  content: '\21d4';
  color: white;
  font-weight: bold;
  font-size: 36px;
  text-align: center;
  line-height: 64px;

  background: $brand-second;
  /* @orange */
  border: 1px solid $brand-primary;
  /* darken(@orange, 5%) */
  border-radius: 50%;
  transition: all 0.3s ease;
  box-shadow:
    0 2px 6px rgba(0, 0, 0, .3),
    inset 0 2px 0 rgba(255, 255, 255, .5),
    inset 0 60px 50px -30px $brand-second;
  /* lighten(@orange, 20%)*/
}

.draggable:after {
  width: 48px;
  height: 48px;
  margin: -24px 0 0 -24px;
  line-height: 48px;
  font-size: 30px;
}

/* -----------------
	ESPACES
---------------------- */
.espace5 {
  padding-top: 5px;
}

.espace10 {
  padding-top: 10px;
}

.espace20 {
  padding-top: 20px;
}

.espace30 {
  padding-top: 30px;
}

.espace40 {
  padding-top: 40px;
}

.espace50 {
  padding-top: 50px;
}
