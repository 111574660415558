/* ==========================================================================
	 header-basic
	 ========================================================================== */

.header-basic {
	position: relative;
	
	.content-header {
		@extend .container-max;
		padding: 3rem;
	}
	
	.logo-header {
		
		>img {
			@extend .img-responsive;
			width: 30vw;
			display: inline-block;
		}
	}
}/* ==========================================================================
	header-basic
	========================================================================== */

.header-basic {
	@extend .text-center;
	position: relative;
	min-height: 40vh;
	border-bottom: solid 3px $brand-primary;
	// padding: 20vh 0 0 0;
	background: linear-gradient(45deg, rgba($brand-primary, 0.3), rgba($brand-second, 0.45));;
	>.container {
		a {
			color: $brand-second !important;
		}
	}
}

// .header_aside {
// @extend .animated;
// @extend .fadeInDown;
// animation-delay: .7s;
// @extend .hidden-xs;
// @extend .hidden-sm;
// box-shadow: 0px 5px 10px rgba($text-color, .25);
// 	position: absolute;
// 	top: 0;
// 	font-size: $font-size-h4;
// 	z-index: 1;
// 	left: 10px;
// 	background-color: rgba(white, .8);
// 	border-radius: 0 0 3rem 3rem;
// 	// border-bottom: solid 1px $brand-second;

// 	@media(min-width: $screen-sm-max) {
// 	padding: 180px 50px 50px 50px;
// 	width: 100%;
// 	}
// 	@media(min-width: $screen-md-max) {
// 	padding: 120px 50px 50px 50px;
// 	width: 30%;
// 	}
// }

.header_address {
}

/* ==========================================================================
	Navbar
	========================================================================== */

.header_content-navbar {
	// background-color: white;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 600;
	transition: height ease .3s;

	&.affix {
		height: 80px;
	}
}

.navbar {
@extend .animated;
@extend .fadeInDown;
animation-delay: .7s;
	width: 100%;
}

.navbar-header {

	@media(min-width: $screen-lg-min) {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 28%;
	}
}

.navbar-brand {
	padding: 20px;
	// max-width: 250px;
	// right: auto;
	// float: left;
	// position: absolute;
	// top: 0;
	// left: 0;

	@media(min-width: $screen-lg-min) {
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 350px;
		padding: 0;
		float: none;
	}

	img {
		@extend .img-responsive;
		width: 200px;
		margin: auto;

		// @media(min-width: $screen-sm-min) {
			// width: 250px;
		// }
	}
}

.navbar-nav {
	@extend .navbar-right;
	margin: 0;

	@media(min-width: $screen-md-min) {

		>li {
			
			>a {
				border-radius: 0px !important;
				position: relative;
			}
		}
	}


}

.index-nav{
	
	@extend .pull-right;
	display: inline-block;
	line-height: $navbar-height;
	padding-right: 10px;
	color: $navbar-default-toggle-icon-bar-bg;
	text-transform: uppercase;
	font-weight: 700;

	@media (min-width: $grid-float-breakpoint) {
		display: none;
	}
}
